import { getStorageItem, setStorageItem } from "components/utility/hotwire/HotwireStorageUtil";
import {
  isBackNavigation,
  isNewTab,
  isRefreshedTab,
  isSameSession,
} from "components/flexComponents/HotwireAffiliateToast/utils/navigation";

export interface MarketingParams {
  [key: string]: string;
}

const STORAGE_KEY = "cststts.mp.v1.mktgpm";
const STORAGE_MAX_AGE = 60 * 60 * 1000 * 24 * 30; // 30 days

const PARAM_KEYS = [
  "acid",
  "bid",
  "cid",
  "clickid",
  "cmid",
  "did",
  "fid",
  "gclid",
  "gclsrc",
  "gid1",
  "gid2",
  "gid3",
  "gid4",
  "gid5",
  "gid6",
  "gid7",
  "gid8",
  "gid9",
  "gid10",
  "gid11",
  "gid12",
  "gid13",
  "gid14",
  "gid15",
  "hwcid",
  "iid",
  "kid",
  "mid",
  "msclkid",
  "msid",
  "nid",
  "nwid",
  "pid",
  "rid",
  "rpid",
  "sid",
  "siteID",
  "subid",
  "targetId",
  "tid",
  "utm_campaign",
  "utm_content",
  "utm_medium",
  "utm_source",
  "utm_term",
  "vid",
  "wid",
  "xid",
  "xsid",
  "zid",
];

const LOWERCASE_PARAM_KEYS = PARAM_KEYS.map((key) => key.toLowerCase());

const getCanonicalParamMap = (entries: Iterable<[string, string]>): MarketingParams => {
  const paramsMap: MarketingParams = {};

  for (const [key, value] of entries) {
    const paramIndex = LOWERCASE_PARAM_KEYS.indexOf(key.toLowerCase());
    if (paramIndex > -1 && value) {
      const canonicalParamKey = PARAM_KEYS[paramIndex];
      paramsMap[canonicalParamKey] = value;
    }
  }

  return paramsMap;
};

const updateMarketingParamsStorage = (params: MarketingParams) => setStorageItem(STORAGE_KEY, params, STORAGE_MAX_AGE);

export const getMarketingParamsFromStorage = (): MarketingParams | null => {
  const marketingParamsFromStorage = getStorageItem<MarketingParams>(STORAGE_KEY);

  if (marketingParamsFromStorage === null) {
    return null;
  }

  return getCanonicalParamMap(Object.entries(marketingParamsFromStorage));
};

const getMarketingParamsFromRoute = (search: string) => {
  const urlSearchParams = new URLSearchParams(search);

  return getCanonicalParamMap(urlSearchParams.entries());
};

/**
 * Determines, based on the navigation state, whether to use the cached marketing params or get them from the URL.
 * This is necessary to achieve consistency with non-Blossom Hotwire pages.
 */
export const shouldUseStoredParams = (storedParams: MarketingParams) =>
  Object.keys(storedParams).length !== 0 && !isNewTab() && (isSameSession() || isRefreshedTab() || isBackNavigation());

/**
 * Returns the marketing params, either from cache or based on the route.
 */
export const getMarketingParams = (search: string): MarketingParams => {
  const storedParams = getMarketingParamsFromStorage();
  const routeParams = getMarketingParamsFromRoute(search);

  if (storedParams !== null && shouldUseStoredParams(storedParams)) {
    return storedParams;
  }

  updateMarketingParamsStorage(routeParams);

  return routeParams;
};
