import * as React from "react";
import { useLocation } from "react-router-dom";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSToast } from "@egds/react-core/toast";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSText } from "@egds/react-core/text";

import { withStores } from "stores";

import { getMarketingParams } from "./utils/marketingParams";
import { getCustomerStats, MarketingChannel } from "./utils/customerStats";
import { Affiliate, getAffiliateFromSiteId } from "./utils/affiliates";

import { HotwireAffiliateToastProps } from "./typings";

import { LazyLoad } from "../../utility/LazyLoad/LazyLoad";

const DISMISS_TIMEOUT = 2000;

export const HotwireAffiliateToast = withStores(
  "context",
  "flexModuleModelStore",
  "hotwireConfig"
)(({ context, flexModuleModelStore, hotwireConfig }: HotwireAffiliateToastProps) => {
  const [dismissed, setDismissed] = React.useState<boolean>(false);
  const [affiliate, setAffiliate] = React.useState<Affiliate | null>(null);
  const { formatText } = useLocalization();
  const location = useLocation();

  React.useEffect(() => {
    // Check affiliate status from Customer Stats API
    const marketingParams = getMarketingParams(location.search);
    getCustomerStats(marketingParams, hotwireConfig).then((stats) => {
      if (stats.marketingChannel === MarketingChannel.AFFILIATE) {
        setAffiliate(getAffiliateFromSiteId(marketingParams.siteID));
      }
    });
  }, [setAffiliate]);

  /* istanbul ignore if */
  if (!flexModuleModelStore) {
    return null;
  }

  // Upon dismissing the toast, ensure it doesn't show up again after giving it time to animate out
  // Without this, the state is reset when the window is resized and the toast pops up again.
  const dismiss = () => setTimeout(() => setDismissed(true), DISMISS_TIMEOUT);

  if (!affiliate || dismissed) {
    return null;
  }

  const { logoUrl, name } = affiliate;

  const header = formatText("hotwire.affiliate-toast.header");
  const actionText = formatText("hotwire.affiliate-toast.dismiss");
  const message = formatText("hotwire.affiliate-toast.message", name);

  return (
    <LazyLoad context={context}>
      <div className="HotwireAffiliateToast" data-testid="HotwireAffiliateToast">
        <EGDSToast show actionText={actionText} onDismiss={dismiss} actionCallback={dismiss}>
          <EGDSLayoutFlex space="three" alignItems="center" alignContent="center">
            <EGDSLayoutFlexItem>
              <img width={48} height={48} alt={name} src={logoUrl} />
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <div>
                <EGDSText size={300} weight="bold" theme="inverse">
                  {header}
                </EGDSText>
                <EGDSText size={300} weight="regular" theme="inverse">
                  {message}
                </EGDSText>
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSToast>
      </div>
    </LazyLoad>
  );
});

export default HotwireAffiliateToast;
