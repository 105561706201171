export interface Affiliate {
  name: string;
  logoUrl: string;
}

const LOGO_BASE_URL = "https://ak-secure.hotwirestatic.com/current/static/images/lib/hw-affiliate-toast/square/";

const AFFILIATES: any = {
  "10098": "Offers.com",
  "10557": "Coupon Cabin",
  "10579": "Rakuten",
  "10621": "RetailMeNot",
  "10622": "Shop.com",
  "12455": "Mr. Rebates",
  "22788": "TopCashBack",
  "32285": "Coupons.com",
  "36345": "Brad’s Deals",
  "49764": "Swagbucks",
  "55048": "RetailMeNot CA",
  "55284": "Ebates CA",
  "55700": "MyPoints.com",
  "69407": "Offers.com",
  "71614": "PayPal",
  "78381": "ID.me",
  "5112233": "Ibotta",
};

const getAffiliateName = (formattedSiteId: string): string => AFFILIATES[formattedSiteId];

const getAffiliateLogoUrl = (formattedSiteId: string): string => `${LOGO_BASE_URL}${formattedSiteId}.png`;

// eg. 1234-abc --> 1234
const formatSiteId = (siteId: string) => siteId.split("-", 2)[0];

export const getAffiliateFromSiteId = (siteId: string): Affiliate | null => {
  if (!siteId) {
    return null;
  }

  const formattedSiteId = formatSiteId(siteId);
  const affiliateName = getAffiliateName(formattedSiteId);

  if (!affiliateName) {
    return null;
  }

  return {
    name: affiliateName,
    logoUrl: getAffiliateLogoUrl(formattedSiteId),
  };
};
