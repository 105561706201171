/*
 * Helpers to determine how the user loaded the current page which are used to choose
 * which customer stats and marketing params to use.
 * This is necessary to achieve consistency with non-Blossom Hotwire pages.
 */

const navType = () => window?.performance?.navigation?.type;
const origin = () => window?.location?.origin;
const referrer = () => document?.referrer;

export const isRefreshedTab = () => navType() === 1;
export const isBackNavigation = () => navType() === 2;
export const isNewTab = () => navType() === 0 && referrer() === "";
export const isSameSession = () => navType() === 0 && referrer() !== "" && referrer()?.indexOf(origin()) === 0;
